import OrderbookPanel from "@/views/dashboard/component/OrderbookPanel";
import TradeListPanel from "@/views/dashboard/component/TradeListPanel";
import SymbolTickerList from "@/views/dashboard/component/SymbolTickerList";
import BreadCrumbs from "@/views/dashboard/component/BreadCrumbs";
import SelectorMulti from "@/views/dashboard/component/SelectorMulti";
import StrategyInfoPanel from "@/views/dashboard/component/StrategyInfoPanel";
import SelectedItemTable from "@/views/dashboard/component/SelectedItemTable";

import ConfirmDialog from "@/components/base/ConfirmDialog";

import {
    get_strategy_param,
    batch_place_order,
    change_leverage,
    get_strategy_orders,
    get_strategy_positions,
    update_strategy_param,
    get_account_list,
    get_strategy_status,
} from "@/api";

import { EventType, } from '@/constants'

import { parse_depth, parse_symbolticker, containsAll, roundN, } from "@/utils";

import { updateExchangeInfoMixin, routerMixins, } from "@/mixins";

import {
    INITIAL_MARGIN, INITIAL_LONG_PERCENTAGE,
    INITIAL_LEVERAGE, INITIAL_ORDER_TYPE, OVERLAY_TIMEOUT,
} from "@/constants";

import * as _ from 'underscore';

export default {
    mixins: [routerMixins, updateExchangeInfoMixin],

    components: {
        OrderbookPanel,
        TradeListPanel,
        SymbolTickerList,
        SelectorMulti,
        ConfirmDialog,
        BreadCrumbs,
        StrategyInfoPanel,
        SelectedItemTable,
    },

    destroyed() {
        clearInterval(this.timer);
        clearInterval(this.timerSlow);
    },

    mounted: function () {
        this.strategy_name = this.$router.currentRoute.name

        this.instance_id = this.$router.currentRoute.query['instanceId']

        this.accountType = this.$router.currentRoute.query['accountType']

        this.isNew = this.strategy_name === 'NewDualPerpetualStrategy'

        this.breadcrumbs_items = [
            {
                text: "Strategies",
                disabled: false,
                href: "/pages/strategies",
            },
            {
                text: this.strategy_name,
                disabled: false,
                href: `/pages/strategies/details/${this.strategy_name}`,
            },
        ],

            this.$EventBus.$on(EventType.LeverageChange, (item) => {

                change_leverage({ symbol: item.symbol, gatewayName: this.gatewayName, leverage: item.leverage, }).then((resp) => {

                    const leverage = resp.data.data.leverage;

                    const key = `${item.symbol}|${item.direction}`
                    if (this.futures_total_positions.hasOwnProperty(key)) {
                        this.futures_total_positions[key].leverage = leverage.leverage;
                    }

                    // this.genOrderTable(false);

                    this.$forceUpdate()
                })
            })

        this.$nextTick(async () => {
            await this.initData();
        })

    },
    watch: {
    },
    computed: {
        futures_total_positions() {

            console.log('accounts:', this.accounts.accounts)

            console.log('gatewayName:', this.gatewayName)

            if (this.gatewayName !== '' && this.accounts.accounts.hasOwnProperty(this.gatewayName)) {
                return  this.accounts.accounts[this.gatewayName].positions;
            }

            return {}
        },
        isPlaceDisabled() {
            // console.log(this.noSelection())
            // console.log(Object.keys(this.futuresExchangeInfo).length === 0)
            // console.log(!(containsAll(this.hasAbDatas, this.longSelect) && containsAll(this.hasAbDatas, this.shortSelect)))
            return this.noSelection() || Object.keys(this.futuresExchangeInfo).length === 0
                || !(containsAll(this.hasAbDatas, this.longSelect) && containsAll(this.hasAbDatas, this.shortSelect));
        },

        isResetDisabled() {
            return false;
        },

        isUpdateDisabled() {
            return (!this.validParam(this.profitTarget) && !this.validParam(this.stopTarget))
                || (this.profitTarget === this.profitTargetOrigin && this.autoCover === this.autoCoverOrigin
                    && this.stopTarget === this.stopTargetOrigin && this.autoStop === this.autoStopOrigin);
        },

        confirmPlaceTxt() {
            return `Are you sure to place (${this.longSelect.length} LONG) and (${this.shortSelect.length} SHORT) orders?`;
        },
    },
    data() {
        return {
            isNew: false,
            gatewayName: '',
            strategy_name: '',
            instance_id: '',
            accountType: '',
            strategy_status: 'UNKNOWN',
            breadcrumbs_items: [],

            overlay: false,

            pnlRatio: 0,
            pnlTotal: 0,
            maxPnlTotal: 0,
            marginTotal: 0,
            params: {},
            profitTarget: undefined,
            autoCover: false,
            profitTargetOrigin: undefined,
            autoCoverOrigin: false,

            stopTarget: undefined,
            autoStop: false,
            stopTargetOrigin: undefined,
            autoStopOrigin: false,

            open_orders: [],
            historical_orders: [],
            positions: [],
            isManaged: true,
            futuresSymbolList: [],
            futuresExchangeInfo: {},
            dlgSubscribe: false,
            dlgPlaceOrder: false,
            longPriceLevel: "b2",
            shortPriceLevel: "a2",
            priceTags: ["a5", "a4", "a3", "a2", "a1", "b1", "b2", "b3", "b4", "b5"],
            long_percent: INITIAL_LONG_PERCENTAGE,
            long_percent_items: [0.1, 0.2, 0.5, 0.7, 1],
            marginInput: INITIAL_MARGIN,
            rules: [(value) => !!value || "Required."],
            orderType: INITIAL_ORDER_TYPE,
            leverage_items: [1, 3, 5, 10, 20, 50],
            leverage: INITIAL_LEVERAGE,
            timer: -1,
            timerSlow: -1,
            orderTable: [],
            subscribedItems: [],
            longSelect: [],
            shortSelect: [],
            symboltickers: [],
            symbolDefList: [],
            symbols: [],
            abdatas: {},
            hasAbDatas: {},
            tickerList: [],
            tickerDatas: {},
            upperColor: "#56f755",
            lowerColor: "#ff0000",
            obSize: ["50%", "50%"],
            tradeSize: ["30%", "30%", "40%"],
            depth: "wait for spot depth data!",
            futuresDepth: "wait for futuresDepth data!",
            symbolTicker: "wait for symbol ticker data!",
            futuresSymbolTicker: "wait for futuresSymbolTicker data!",
            orderBookTicker: "wait for orderbook ticker data",
            futuresOrderBookTicker: "wait for futuresOrderBookTicker data",
            accounts: {},
        };
    },

    methods: {

        genTPSLInput(val) {
            if (this.validParam(val)) {
                this.profitTarget = val.profitTarget;
                this.profitTarget = this.validParam(this.profitTarget) ? parseFloat(this.profitTarget) : undefined;
                this.autoCover = val.autoCover ? true : false;

                this.profitTargetOrigin = this.profitTarget;
                this.autoCoverOrigin = this.autoCover;

                this.stopTarget = val.stopTarget;
                this.stopTarget = this.validParam(this.stopTarget) ? parseFloat(this.stopTarget) : undefined;
                this.autoStop = val.autoStop ? true : false;

                this.stopTargetOrigin = this.stopTarget;
                this.autoStopOrigin = this.autoStop;
            } else {
                this.profitTarget = undefined;
                this.autoCover = false;
                this.stopTarget = undefined;
                this.autoStop = false;
            }
        },

        async initData() {

            this.tickerList = [];
            this.tickerDatas = {};
            this.orderTable = [];
            this.abdatas = {};
            this.hasAbDatas = {};

            this.accounts = await get_account_list();

            this.sockets.subscribe("Depth", (event) => {
                const { symbol, accountType, asks, ask_key, bids, bid_key } =
                    parse_depth(event);

                if (this.symbols.indexOf(symbol) >= 0 && accountType === this.accountType) {
                    this.abdatas[ask_key] = asks;
                    this.abdatas[bid_key] = bids;
                    this.hasAbDatas[symbol] = true;
                }
            });

            this.sockets.subscribe("SymbolTicker", (event) => {
                const { symbol, accountType, ev, key } = parse_symbolticker(event);

                if (this.symbols.indexOf(symbol) >= 0 && accountType === this.accountType) {
                    this.tickerDatas[key] = ev;
                }

            });

            this.fetchExchangeInfo().then(({
                new_total_infos, }) => {
                const exchange = new_total_infos[this.accountType]
                if (exchange !== undefined) {
                    this.futuresSymbolList = exchange.filterLst;
                    this.futuresExchangeInfo = exchange.exchangeInfo;
                }
            })

            get_strategy_param(this.strategy_name, this.instance_id)
                .then((strategy_param) => {

                    this.gatewayName = strategy_param.gateways[0];

                    this.params = strategy_param.params;
                    this.genTPSLInput(this.params);

                    this.symbolDefList = strategy_param.symbols;
                    this.symbols = strategy_param.symbols.map((item) => item.symbol);
                    this.subscribedItems = this.symbols;

                })
                .catch((reason) => {
                    console.error(reason);
                });

            this.timer = setInterval(() => {

                this.tickerList = Object.keys(this.tickerDatas)
                    .sort()
                    .map((item) => this.tickerDatas[item]);

                this.genOrderTable(false);

                get_strategy_positions(this.strategy_name, this.instance_id).then(({ positions, pnlTotal, maxPnlTotal, marginTotal, pnlRatio, }) => {

                    this.positions = positions;
                    this.pnlTotal = pnlTotal;
                    this.maxPnlTotal = maxPnlTotal;
                    this.marginTotal = marginTotal;
                    this.pnlRatio = pnlRatio;
                }).catch((reason) => {
                    console.error(reason);
                    this.logout()
                })

                get_strategy_status(this.strategy_name, this.instance_id).then((status) => {
                    this.strategy_status = status;
                }).catch((reason) => {
                    console.error(reason);
                });

                this.$forceUpdate();
            }, 3000);

            this.timerSlow = setInterval(() => {

                get_strategy_orders(this.strategy_name, this.instance_id).then(({ open_orders, historical_orders, }) => {
                    this.open_orders = open_orders;
                    this.historical_orders = historical_orders;
                    // console.log(historical_orders)
                }).catch((reason) => {
                    console.error(reason);
                    // this.logout()
                })

                this.$forceUpdate();
            }, 6000);
        },

        validParam(param) {
            return (!_.isNull(param) && !_.isUndefined(param)) && param !== ''
        },

        noSelection() {
            return this.longSelect.length == 0 && this.shortSelect.length == 0;
        },

        updateParams() {
            const params = {
                profitTarget: this.profitTarget,
                autoCover: this.autoCover,
                stopTarget: this.stopTarget,
                autoStop: this.autoStop,
            }

            update_strategy_param({ strategyName: this.strategy_name, instanceId: this.instance_id, params, updateType: "PARAMS", }).then((strategy_param) => {
                this.params = strategy_param.params;
                this.genTPSLInput(this.params);
            })
        },

        tryToSubscribe() {
            this.dlgSubscribe = true;
        },
        closeDlgSubscribe() {
            this.dlgSubscribe = false;
        },

        tryToPlaceOrder() {
            this.dlgPlaceOrder = true;
        },
        closeDlgPlace() {
            this.dlgPlaceOrder = false;
        },

        genOrderTable(resetRatio = false) {
            const total_count = this.longSelect.length + this.shortSelect.length;

            if (total_count === 0) {
                this.orderTable = [];
                return;
            }
            const long_ratio =
                this.longSelect.length === 0
                    ? 0
                    : this.shortSelect.length === 0
                        ? 1.0 / this.longSelect.length
                        : this.long_percent / this.longSelect.length;
            const short_ratio =
                this.shortSelect.length === 0
                    ? 0
                    : this.longSelect.length === 0
                        ? 1.0 / this.shortSelect.length
                        : (1 - this.long_percent) / this.shortSelect.length;

            this.orderTable = [];

            let index = 0;

            const addOrderToOrderTable = (symbol, direction, percentage) => {
                const key = `${symbol}_${this.accountType}`;
                if (this.tickerDatas.hasOwnProperty(key)) {
                    const ticker = this.tickerDatas[key];
                    const price = ticker.lastPrice;
                    const qty = ticker.lastQty;

                    let leverage = INITIAL_LEVERAGE

                    const posKey = `${symbol}|${direction}`

                    const f_positions = this.futures_total_positions

                    if (f_positions.hasOwnProperty(posKey)) {
                        leverage = this.futures_total_positions[posKey].leverage
                    } else {
                        console.log('dont have key', posKey)
                    }

                    const orderNum = (this.marginInput * percentage) * leverage / price;

                    let orderQty = 0;
                    let stepSize = 1;

                    if (this.futuresExchangeInfo.hasOwnProperty(symbol)) {
                        const precision = this.futuresExchangeInfo[symbol].qtyScaleNew;
                        orderQty = roundN(orderNum, precision)
                    }

                    const item = {
                        index: index++,
                        symbol,
                        leverage,
                        direction,
                        percentage,
                        orderNum,
                        stepSize,
                        orderQty,
                    }

                    this.orderTable.push(item);
                } else {
                }
            }

            this.longSelect.forEach((item) => addOrderToOrderTable(item, 'LONG', long_ratio));
            this.shortSelect.forEach((item) => addOrderToOrderTable(item, 'SHORT', short_ratio));

        },
        onLongPriceLevelChange(e) {
        },
        onShortPriceLevelChange(e) {
        },
        onPercentChange(e) {
            this.genOrderTable(false);
        },
        onSelectedItemsChange(e) {
            this.subscribedItems = e;
        },
        onLongChange(e) {
            // console.log('onLongChange:', e)
            this.longSelect = e;
            this.genOrderTable(true);
        },
        onShortChange(e) {
            // console.log('onShortChange:', e)
            this.shortSelect = e;
            this.genOrderTable(true);
        },
        getShowData(symbol, accountType, ab) {
            const key = `${symbol}_${accountType}_${ab}`;
            if (this.abdatas === {} || !this.abdatas.hasOwnProperty(key)) {
                return [{}, {}, {}, {}, {}];
            }
            return this.abdatas[key];
        },
        reset() {
            this.genTPSLInput(this.params);
            this.marginInput = INITIAL_MARGIN;
            this.leverage = INITIAL_LEVERAGE;
            this.long_percent = INITIAL_LONG_PERCENTAGE;
            this.orderType = INITIAL_ORDER_TYPE;
            this.long_percent_items = [0.1, 0.2, 0.5, 0.7, 1];
            this.leverage_items = [1, 3, 5, 10, 20, 50];
            this.$EventBus.$emit("ls_clear");
            this.longSelect = [];
            this.shortSelect = [];
        },
        getPrice(symbol, orderType, direction) {

            if (orderType === 'MARKET') {
                return 0
            }

            const longPriceLevel = direction === 'LONG' ? this.longPriceLevel : this.shortPriceLevel;

            const isAsk = longPriceLevel.substring(0, 1) === 'a';

            const key_suffix = isAsk ? 'asks' : 'bids';

            const level = parseInt(longPriceLevel.substring(1, 2))

            const key = `${symbol}_${this.accountType}_${key_suffix}`

            const priceItem = isAsk ? this.abdatas[key][this.abdatas[key].length - level] : this.abdatas[key][level - 1]

            return priceItem.price

        },
        placeOrders() {

            const strategyName = this.isManaged ? this.strategy_name : '';

            const instanceId = this.isManaged ? this.instance_id : '';

            const orders = this.orderTable.map((orderInput) => {

                const symbol = orderInput.symbol;

                // console.log(this.futuresExchangeInfo[symbol]);

                const orderSide = orderInput.direction === 'SHORT' ? 'SELL' : 'BUY';

                const price = this.getPrice(symbol, this.orderType, orderInput.direction);

                const params = {
                    gatewayName: this.gatewayName,
                    symbol: orderInput.symbol,
                    accountType: this.accountType,
                    strategyName,
                    instanceId,
                    orderSide,
                    positionSide: orderInput.direction,
                    orderType: this.orderType,
                    qty: orderInput.orderQty,
                    workingType: 'MARK_PRICE',
                    price,
                };

                if (this.orderType == 'LIMIT') {
                    params.timeInForce = 'GTC'
                }

                // console.log(params)

                return params

            })

            batch_place_order(orders, strategyName, instanceId)

            this.closeDlgPlace();
        },

        subscribe() {
            console.log(this.subscribedItems)

            const symbols = this.subscribedItems.map((symbol) => {
                return {
                    orderAmt: 0,
                    orderQty: 0,
                    symbol,
                    accountType: this.accountType,
                }
            })

            this.closeDlgSubscribe();

            update_strategy_param({ strategyName: this.strategy_name, instanceId: this.instance_id, symbols, updateType: "SYMBOLS", }).then((strategy_param) => {
                console.log(strategy_param)
            })

            this.overlay = true;
            setTimeout(() => {
                this.initData();
                this.reset();
                this.overlay = false;
            }, OVERLAY_TIMEOUT)

        },

        resetSubscription() {
            console.log('resetSubscription')
            const temp = this.symbols
            this.symbols = [];
            setTimeout(() => {
                this.symbols = temp;
            }, 0)
        },
    },
};
